import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import useAnalytics from "hooks/analytics";
import useAuthUser from "hooks/auth-user";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { UserActionEvent } from "utils/analytics/events";
import { Button } from "../../components";
import ClinicList from "../../components/clinic-list";
import useLanguage from "../../hooks/language";
import Appointment, {
    MIN_BOOK_APPOINTMENT_HOUR_DELAY,
    canEdit,
} from "../../models/appointment";
import Clinic from "../../models/clinic";
import ENDPOINTS from "../../utils/endpoints";
import PaymentForm from "./payment-form";

interface ScheduleAppointmentModalProps {
    onClose?: () => void;
    onConfirm?: () => void;
    appointment?: Appointment;
    isVisible?: boolean;
}

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

const ScheduleAppointmentModal: React.FunctionComponent<ScheduleAppointmentModalProps> = ({
    appointment,
    onClose,
    onConfirm,
    isVisible,
}: ScheduleAppointmentModalProps) => {
    const [authUser] = useAuthUser();
    const { translations, language } = useLanguage();
    const t = translations.appointmentsSection;
    const [analytics] = useAnalytics();

    // states

    const [loading, setLoading] = useState<boolean>(false);
    const [isoDate, setIsoDate] = useState<string | null>(null);
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
    const [clinics, setClinics] = useState<Clinic[]>([]);
    const [selectedClinic, setSelectedClinic] = useState<number | null>(null);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);

    //This is in order to reset the form payment state on click outside
    useEffect(() => {
        if (!isVisible) setShowPaymentForm(false);
        else fetchClinics();
    }, [isVisible]);

    // Handlers

    const onSubmit = () => {
        if (!appointment) return;

        if (appointment.shouldRetake) {
            bookRetake();
        } else if (canEdit(appointment)) {
            updateAppointment();
        }
    };

    // Network
    const fetchClinics = async () => {
        const validPrepPhoneAppointment = authUser?.phoneAppointments.find(
            (pa) =>
                pa.isAboutPrep &&
                pa.linkableType === "appointment" &&
                pa.linkableId === appointment?.id &&
                pa.status !== "REJECTED" &&
                pa.status !== "CANCELED"
        );
        let date;
        if (validPrepPhoneAppointment) {
            date = DateTime.fromISO(validPrepPhoneAppointment.startAt)
                .plus({
                    hour: MIN_BOOK_APPOINTMENT_HOUR_DELAY,
                })
                .toISO();
            setStartDate(date);
        } else setStartDate(undefined);
        try {
            const { data } = await Axios.get(ENDPOINTS.CLINICS);
            setClinics(data);
        } catch (error) {
            console.log(error);
            NotificationManager.error(error);
        }
    };

    const updateAppointment = async () => {
        if (!appointment) return;

        setLoading(true);

        try {
            await Axios.put(ENDPOINTS.APPOINTMENT(appointment.id), {
                datetime: isoDate,
                clinicId: selectedClinic,
            });

            if (onConfirm) {
                onConfirm();
            }

            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.ScreeningAppointmentRescheduled);

            NotificationManager.success(
                translations.notification.rescheduledAppointment
            );
        } finally {
            setShowPaymentForm(false);
            setLoading(false);
        }
    };

    const bookRetake = async () => {
        if (!appointment) return;

        setLoading(true);

        try {
            await Axios.post(ENDPOINTS.BOOK_RETAKE_APPOINTMENT, {
                appointmentToRetakeId: appointment.id,
                datetime: isoDate,
                clinicId: selectedClinic,
            });
            if (onConfirm) onConfirm();
            NotificationManager.success(
                translations.notification.createdAppointment
            );
        } finally {
            setShowPaymentForm(false);
            setLoading(false);
        }
    };

    // Rendering

    const hasToPay =
        appointment &&
        !appointment.shouldRetake &&
        !appointment.canRescheduleFreely;

    return (
        <div>
            {!showPaymentForm ? (
                <ClinicList
                    clinics={clinics}
                    onDateSelect={setIsoDate}
                    onClinicSelect={setSelectedClinic}
                    hasDateSelect={true}
                    appointment={appointment}
                    startDate={startDate}
                    isDisplayedInModal={true}
                />
            ) : (
                <Elements stripe={stripePromise} options={{ locale: language }}>
                    <PaymentForm
                        appointment={appointment}
                        datetime={isoDate}
                        clinicId={selectedClinic}
                        onCompletePayment={onConfirm}
                        onClose={() => setShowPaymentForm(false)}
                    />
                </Elements>
            )}
            <br />
            <div className="dual-button-holder">
                {!hasToPay && (
                    <>
                        <Button onClick={onClose} type="secondary">
                            {t.scheduleModal.exitButtonText}
                        </Button>
                        <Button
                            loading={loading}
                            disabled={!isoDate || !selectedClinic}
                            onClick={onSubmit}
                        >
                            {t.scheduleModal.confirmButtonText}
                        </Button>
                    </>
                )}
                {hasToPay && !showPaymentForm && (
                    <>
                        <Button onClick={onClose} type="secondary">
                            {t.scheduleModal.exitButtonText}
                        </Button>
                        <Button
                            disabled={!isoDate || !selectedClinic}
                            loading={loading}
                            onClick={() => setShowPaymentForm(true)}
                        >
                            {t.scheduleModal.payButtonText}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ScheduleAppointmentModal;
