import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import {
    StripeCardCvcElementOptions,
    StripeCardExpiryElementOptions,
    StripeCardNumberElementOptions,
    StripeElementStyle,
} from "@stripe/stripe-js";
import Axios from "axios";
import useAnalytics from "hooks/analytics";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { FollowUpType } from "models/appointment";
import { UserActionEvent } from "utils/analytics/events";
import { Button } from "../../../../components";
import useAuthUser from "../../../../hooks/auth-user";
import useLanguage from "../../../../hooks/language";
import ENDPOINTS from "../../../../utils/endpoints";
import styles from "./payment-form.module.css";
import routes from "utils/routes";

const cardElementStyle: StripeElementStyle = {
    base: {
        backgroundColor: "#FFFFFF",
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        iconColor: "#CDCDCD",
        textAlign: "justify",
    },
};

interface PaymentFormProps {
    onCompletePayment: (canMakePayment: boolean) => void;
    onSkipPaymentClicked: (willMakeCashPayment: boolean) => void;
    appointmentFollowUpType: FollowUpType;
    submitButtonText: string;
}

enum ScreeningPaymentType {
    ApplePay = "Apple Pay",
    Cash = "Cash",
    CreditCard = "Credit Card",
    GooglePay = "Google Pay",
}

export default function PaymentForm({
    onCompletePayment,
    onSkipPaymentClicked,
    appointmentFollowUpType,
    submitButtonText,
}: PaymentFormProps): React.ReactElement {
    const { translations } = useLanguage();
    const [authUser] = useAuthUser();
    const [analytics] = useAnalytics();
    const stripe = useStripe();
    const elements = useElements();
    const localization = translations.upfrontPaymentSection;
    const showCashPaymentOption =
        !!authUser?.ramqNumber &&
        appointmentFollowUpType === FollowUpType.Regular;

    const cardNumberOptions: StripeCardNumberElementOptions = {
        iconStyle: "solid",
        placeholder: localization.paymentForm.cardNumber,
        showIcon: true,
        style: cardElementStyle,
    };

    const cardExpiryOptions: StripeCardExpiryElementOptions = {
        placeholder: localization.paymentForm.cardExpiry,
        style: cardElementStyle,
    };

    const cardCvcOptions: StripeCardCvcElementOptions = {
        placeholder: localization.paymentForm.cardCvc,
        style: cardElementStyle,
    };

    // States

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCardCvcValid, setIsCardCvcValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);

    // Network

    const handleCardDetails = async (tokenID: string) => {
        if (!authUser || !authUser.lastUserQuestionnaire) {
            return;
        }

        try {
            await Axios.put(ENDPOINTS.USER_PAYMENT_INFO, {
                token: tokenID,
            });

            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.ScreeningPaymentSubmitted, {
                    method: ScreeningPaymentType.CreditCard,
                });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handlers

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
            return;
        }

        const { token, error } = await stripe.createToken(cardNumberElement);

        if (error) {
            NotificationManager.error(error.message);
            console.error(error);
            setIsSubmitting(false);
        } else if (token) {
            await handleCardDetails(token.id);
            setIsSubmitting(false);
            return onCompletePayment(true);
        }
    };

    const onPayWithCashClicked = (): void => {
        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.ScreeningPaymentSubmitted, {
                method: ScreeningPaymentType.Cash,
            });
        onSkipPaymentClicked(false);
    };

    // Rendering
    return (
        <div>
            <label htmlFor="card-element">
                {localization.paymentForm.cardLabel}
            </label>
            <div id="card-element" className={styles["card-container"]}>
                <div className={styles["card-container__item-main"]}>
                    <CardNumberElement
                        options={cardNumberOptions}
                        onChange={(e) =>
                            setIsCardNumberValid(!e.error && e.complete)
                        }
                    />
                </div>
                <div className={styles["card-container__item"]}>
                    <CardExpiryElement
                        options={cardExpiryOptions}
                        onChange={(e) =>
                            setIsCardExpiryValid(!e.error && e.complete)
                        }
                    />
                </div>
                <div className={styles["card-container__item-end"]}>
                    <CardCvcElement
                        options={cardCvcOptions}
                        onChange={(e) =>
                            setIsCardCvcValid(!e.error && e.complete)
                        }
                    />
                </div>
            </div>
            {showCashPaymentOption && (
                <div
                    className={`${styles["payment-options"]} ${styles["single-line"]}`}
                >
                    <span>{localization.paymentForm.paymentOptionIntro}</span>
                    {!isSubmitting ? (
                        <a onClick={() => onPayWithCashClicked()}>
                            {localization.paymentForm.paymentOptionCash}
                        </a>
                    ) : (
                        <span>
                            {localization.paymentForm.paymentOptionCash}
                        </span>
                    )}
                </div>
            )}
            <div className={styles["save-button"]}>
                <Button
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={
                        !stripe ||
                        !isCardNumberValid ||
                        !isCardExpiryValid ||
                        !isCardCvcValid ||
                        !authUser
                    }
                >
                    {submitButtonText}
                </Button>
            </div>
            {!authUser?.ramqNumber && (
                <div className={styles.ramqWarning}>
                    <p>
                        ☝️&nbsp;
                        {localization.missingRamqWarning.question}{" "}
                        <a href={routes.SCREENING}>
                            {localization.missingRamqWarning.description}
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
}
