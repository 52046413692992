export default {
    LOGIN: "/login",
    EMAIL_CONFIRMATION: "/email-confirmation/:token",
    PASSWORD_FORGOTTEN: "/password-forgotten",
    PASSWORD_RESET: "/password-reset/:token",
    UNSUBSCRIBE: "/unsubscribe",
    HOME: "/home",
    TFA_INPUT: "/tfa-input",
    TFA_SETTINGS: "/tfa-settings",
    PROFILE: "/profile",
    SCREENING: "/home/screening",
    APPOINTMENTS: "/home/appointments",
    APPOINTMENT_CONFIRMATION: (
        params?: AppointmentConfirmationRouteParams
    ): string => {
        if (params) {
            return `/home/appointments/${params.type}/${params.id}/confirmation`;
        }

        return "/home/appointments/:type/:id/confirmation";
    },
    PREP: "/home/prep",
    SIGN_UP: "/signup",
};

export interface AppointmentConfirmationRouteParams {
    type: "screening" | "phone";
    id: number;
}
