import Appointment from "./appointment";
import User from "./user";

export type PhoneAppointmentStatus =
    | "CANCELED"
    | "REJECTED"
    | "COMPLETED"
    | "INVITED"
    | "SCHEDULED";
export type PhoneAppointmentType =
    | "REGULAR_FOLLOW_UP"
    | "POSITIVE_FOLLOW_UP"
    | "PRIORITY_FOLLOW_UP"
    | "PREP_ELIGIBILITY"
    | "PREP_ELIGIBILITY_FOLLOW_UP"
    | "INDEPENDENT";
export type LinkableType = "user" | "appointment";

export default interface PhoneAppointment {
    id: number;
    appointment: Appointment;
    createdAt: string;
    updatedAt: string;
    startAt: string;
    isMissed: boolean;
    status: PhoneAppointmentStatus;
    linkableId: number;
    linkableType: LinkableType;
    type: PhoneAppointmentType;
    isAboutPrep: boolean;
    addToGoogleCalendarLink?: string;
    addToOutlookCalendarLink?: string;
}

// Constants

export const MAX_BOOK_PHONE_APPOINTMENT_DATE_DAYS = 8;
export const PHONE_APPOINTMENT_TYPE = "phone-appointment";

// Helpers

export const isUpcoming = ({ status, isMissed }: PhoneAppointment): boolean =>
    ["SCHEDULED", "INVITED"].indexOf(status) >= 0 && !isMissed;

export const isCanceled = ({ status }: PhoneAppointment): boolean =>
    status === "CANCELED";

export const isMissed = ({ isMissed }: PhoneAppointment): boolean => isMissed;

export const isRejected = ({ status }: PhoneAppointment): boolean =>
    status === "REJECTED";

export const getLinkedAppointmentId = ({
    linkableId,
    linkableType,
}: PhoneAppointment): number | undefined => {
    return linkableType === "appointment" ? linkableId : undefined;
};

export const getLinkedAppointmentDateTime = (
    appointmentId: number,
    authUser: User | null
): string | undefined => {
    if (!authUser) return undefined;
    const linkedAppointment = authUser.appointments.find(
        (appointment: Appointment) => appointment.id === appointmentId
    );
    return linkedAppointment?.datetime;
};
