import { PropsWithChildren } from "react";
import styles from "./list.module.css";

type ListProps = PropsWithChildren<{ className?: string }>;
/**
 * Renders a list of items as a block with no extra default spacing
 * Could be extended to include ordered VS non-ordered, or define a custom marker
 */
export function List({ className = "", children }: ListProps): JSX.Element {
    return <ul className={`${styles.list} ${className}`}>{children}</ul>;
}

type ItemProps = PropsWithChildren<{ noMarker?: boolean; className?: string }>;
/**
 * Renders a list item with or without a check marker
 * Could be extended in the future to include other types of markers
 */
export function ListItem({
    noMarker = false,
    className = "",
    children,
}: ItemProps): JSX.Element {
    return (
        <li
            className={`${styles.list__item} ${
                noMarker ? styles["list__item--no-marker"] : ""
            } ${className}`}
        >
            {children}
        </li>
    );
}
