import styles from "./tfa-input.module.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { Footer, Header, Button, Field, Checkbox } from "../../components";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";
import useAuthToken from "../../hooks/auth-token";
import ROUTES from "../../utils/routes";
import { setCookie } from "../../utils/local-storage";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";
import IndexPointingUpEmoji from "assets/images/index-pointing-up.png";
import { NotificationManager } from "react-notifications";
const REMEMBER_ME_TOKEN_DURATION = 30;
const REMEMBER_ME_TOKEN_NAME = "rememberMeToken";
const TFA_RESEND_COUNTDOWN_DURATION = 30; // Total countdown duration

const TfaInputPage: React.FunctionComponent = () => {
    const { push, replace } = useHistory();
    const setAuthToken = useAuthToken()[1];
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const { translations } = useLanguage();
    const t = translations.tfaInput;
    const [analytics] = useAnalytics();

    const [submitted, setSubmitted] = useState(false);
    const [code, setCode] = useState("");
    const [rememberBrowser, setRememberBrowser] = useState(false);
    const [loading, setLoading] = useState(false);

    const sentBy = searchParams.get("sentBy") || undefined;
    const sentTo = searchParams.get("sentTo") || undefined;

    const getInitialTimerValue = () => {
        const savedTimerEnd = localStorage.getItem("resendCodeTimerEnd");

        if (savedTimerEnd) {
            const remainingTime = Math.max(
                0,
                Math.ceil((parseInt(savedTimerEnd) - Date.now()) / 1000)
            );
            return remainingTime;
        }

        return TFA_RESEND_COUNTDOWN_DURATION;
    };

    const [resendCodeTimer, setResendCodeTimer] = useState(
        getInitialTimerValue
    );

    useEffect(() => {
        if (resendCodeTimer <= 0) return;

        if (!localStorage.getItem("resendCodeTimerEnd")) {
            localStorage.setItem(
                "resendCodeTimerEnd",
                (Date.now() + resendCodeTimer * 1000).toString()
            );
        }

        const intervalId = setInterval(() => {
            setResendCodeTimer((prev) => {
                const newTime = prev - 1;

                if (newTime <= 0) {
                    clearInterval(intervalId);
                    localStorage.removeItem("resendCodeTimerEnd");
                    return 0;
                }

                return newTime;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [resendCodeTimer]);

    // Validations

    const tfaError = code.length < 6 ? t.codeError : null;
    const disabled = code.length === 0 || (submitted && tfaError !== null);

    // Network

    const verify = async () => {
        setSubmitted(true);

        if (tfaError) return;

        setLoading(true);

        const tfaToken = searchParams.get("tfaToken") || undefined;

        try {
            const { data } = await Axios.post(ENDPOINTS.VERIFY_TFA, {
                code,
                tfaToken,
                rememberBrowser,
            });

            const eventProperties = {
                type: "tfa",
                method: sentBy,
                stopAsking: rememberBrowser,
            };
            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);
            analytics
                ?.web()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);
            const { authToken, rememberMeToken } = data;
            if (rememberMeToken) {
                setCookie(
                    REMEMBER_ME_TOKEN_NAME,
                    rememberMeToken,
                    REMEMBER_ME_TOKEN_DURATION
                );
            }
            setAuthToken(authToken);
            localStorage.removeItem("resendCodeTimerEnd");
            push(ROUTES.HOME);
        } finally {
            setLoading(false);
        }
    };

    const handleSendNewTfaCode = async () => {
        if (resendCodeTimer > 0) {
            return;
        }

        const oldTfaToken = searchParams.get("tfaToken");

        if (!oldTfaToken) {
            return;
        }

        try {
            const { data } = await Axios.post(ENDPOINTS.REQUEST_TFA_CODE, {
                tfaToken: oldTfaToken,
            });

            const { tfaToken, sentTo, sentBy } = data;

            const newSearchParams = new URLSearchParams({
                tfaToken,
                sentTo,
                sentBy,
            });

            replace({ search: newSearchParams.toString() });
            setResendCodeTimer(TFA_RESEND_COUNTDOWN_DURATION);
            localStorage.setItem(
                "resendCodeTimerEnd",
                (Date.now() + TFA_RESEND_COUNTDOWN_DURATION * 1000).toString()
            );
            NotificationManager.success(t.sendNewCodeSuccess);
        } catch (error) {
            NotificationManager.error(t.sendNewCodeError);
        }
    };

    return (
        <div className="page">
            <Header />

            <div className="preauth-container">
                <h2>{t.title}</h2>
                {sentBy && sentTo && <p>{t.sentTo(sentBy, sentTo)}</p>}

                <div className="input-holder">
                    <label>{t.fieldLabel}</label>
                    <Field
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        onPressEnter={verify}
                        error={submitted ? tfaError : null}
                    />
                    <br />
                    <Checkbox
                        checked={rememberBrowser}
                        onChange={setRememberBrowser}
                    >
                        {t.checkboxLabel}
                    </Checkbox>
                </div>

                <Button disabled={disabled} loading={loading} onClick={verify}>
                    {t.verifyButton}
                </Button>

                <div className={styles.resendCodeContainer}>
                    <button
                        disabled={resendCodeTimer > 0}
                        className={styles.resendCodeButton}
                        onClick={async () => await handleSendNewTfaCode()}
                    >
                        {t.resendCode}{" "}
                        {resendCodeTimer > 0 ? `(${resendCodeTimer})` : ""}
                    </button>
                </div>

                <div className={styles.notificationCard}>
                    <div className={styles.notificationContent}>
                        <span
                            className={styles.notificationIcon}
                            role="img"
                            aria-hidden={true}
                        >
                            <img
                                className={styles.notificationIconImage}
                                src={IndexPointingUpEmoji}
                                alt="pointing finger"
                            />
                        </span>
                        <div className={styles.notificationText}>
                            <h2 className={styles.notificationTextTitle}>
                                {t.verifySpanBoxTitle}
                            </h2>
                            <p className={styles.notificationTextSubtitle}>
                                {t.verifySpanBoxSubTitle}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default TfaInputPage;
