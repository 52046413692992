import appointmentsIcon from "assets/images/appointments-icon.svg";
import prepIcon from "assets/images/prep-icon.svg";
import screeningIcon from "assets/images/screening-icon.svg";
import useAnalytics from "hooks/analytics";
import useLanguage from "hooks/language";
import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { UserActionEvent } from "utils/analytics/events";
import ROUTES from "utils/routes";
import styles from "./home.module.css";
import { useUpcomingAppointmentInfo } from "./useUpcomingAppointmentInfo";

type Props = {
    onScreeningLinkClick: (e: MouseEvent) => void;
};

export const Nav = ({ onScreeningLinkClick }: Props): React.ReactElement => {
    const { translations } = useLanguage();
    const [analytics] = useAnalytics();
    const {
        isLastAppointmentScheduled,
        hasFollowUp,
    } = useUpcomingAppointmentInfo();

    const isScreeningDisabled = isLastAppointmentScheduled || hasFollowUp;

    const onCreateAppointmentClicked = () => {
        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.ScreeningRequestedInitiated);
    };

    return (
        <div>
            <div>
                <Link to={ROUTES.APPOINTMENTS}>
                    <button>
                        <img alt="" src={appointmentsIcon} />
                        <h3>
                            {
                                translations.homePage.subheaderTab
                                    .myAppointmentTitle
                            }
                        </h3>
                    </button>
                </Link>

                <Link
                    to={ROUTES.SCREENING}
                    className={isScreeningDisabled ? styles.disabledLink : ""}
                    onClick={
                        isScreeningDisabled
                            ? (e: MouseEvent) => e.preventDefault()
                            : onScreeningLinkClick
                    }
                >
                    <button onClick={onCreateAppointmentClicked}>
                        <div
                            disabled-tool-tip={
                                isLastAppointmentScheduled
                                    ? translations.homePage.tooltipTab.screening
                                    : translations.homePage.tooltipTab
                                          .phoneAppointment
                            }
                        >
                            <img alt="" src={screeningIcon} />
                        </div>
                        <h3>
                            {translations.homePage.subheaderTab.screeningTitle}
                        </h3>
                    </button>
                </Link>

                <Link to={ROUTES.PREP}>
                    <button>
                        <img alt="" src={prepIcon} />
                        <h3>
                            {translations.homePage.subheaderTab.myPrepTitle}
                        </h3>
                    </button>
                </Link>
            </div>
        </div>
    );
};
