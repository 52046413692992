import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Axios from "axios";
import {
    Footer,
    Header,
    Button,
    Field,
    PasswordValidator,
} from "../../components";
import useLanguage from "../../hooks/language";
import ENDPOINTS, { BASE_URL } from "../../utils/endpoints";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";
import routes from "utils/routes";
import { NotificationManager } from "react-notifications";
import { useAsync } from "react-async-hook";
import { Loader } from "react-feather";
import styles from "../../pages/home/home.module.css";

export default function PasswordResetPage(): React.ReactElement {
    const { params } = useRouteMatch<{ token: string }>();
    const { token } = params;
    const { push } = useHistory();
    const { translations, language } = useLanguage();
    const { loading: isValidatingToken } = useAsync(
        async (token: string, language: string) => {
            try {
                const response = await fetch(
                    `${BASE_URL}${ENDPOINTS.VALIDATE_RESET_PASSWORD_TOKEN}?token=${token}`,
                    {
                        method: "GET",
                        headers: {
                            "accept-language": language,
                        },
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                NotificationManager.error(error.message);
                push(routes.PASSWORD_FORGOTTEN);
            }
        },
        [token, language]
    );

    const t = translations.resetPassword;
    const [analytics] = useAnalytics();

    // States

    const [submitted, setSubmitted] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [loading, setLoading] = useState(false);
    // Errors

    const passwordError = !passwordValid ? t.invalidPasswordError : null;
    const confirmationError =
        password !== passwordConfirmation ? t.passwordDontMatchError : null;
    const hasError = passwordError !== null || confirmationError !== null;
    const disabled =
        password.length === 0 ||
        passwordConfirmation.length === 0 ||
        (submitted && hasError);

    const resetPassword = async () => {
        setSubmitted(true);

        if (hasError) return;

        setLoading(true);

        try {
            await Axios.put(ENDPOINTS.RESET_PASSWORD, {
                token,
                newPassword: password,
            });
            analytics?.cdp()?.trackEvent(UserActionEvent.PasswordReset);
            NotificationManager.success(t.successMessage);
            push(routes.LOGIN);
        } finally {
            setLoading(false);
        }
    };

    // Rendering
    return (
        <>
            {isValidatingToken ? (
                <div className={styles["loader-container"]}>
                    <div className={styles["loader"]}>
                        <Loader className="spinorama" />
                        <p>{translations.homePage.loadingInfo}</p>
                    </div>
                </div>
            ) : (
                <div className="page">
                    <Header />
                    <div className="preauth-container">
                        <h2>{t.header}</h2>
                        <div className="input-holder">
                            <label>{t.passwordLabel}</label>
                            <PasswordValidator
                                password={password}
                                setValid={setPasswordValid}
                            />
                            <Field
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                onPressEnter={resetPassword}
                                error={submitted ? passwordError : null}
                            />
                        </div>
                        <div className="input-holder">
                            <label>{t.confirmationLabel}</label>
                            <Field
                                value={passwordConfirmation}
                                onChange={(e) =>
                                    setPasswordConfirmation(e.target.value)
                                }
                                type="password"
                                onPressEnter={resetPassword}
                                error={submitted ? confirmationError : null}
                            />
                        </div>
                        <Button
                            disabled={disabled}
                            loading={loading}
                            onClick={resetPassword}
                        >
                            {t.changeButtonText}
                        </Button>
                    </div>

                    <Footer />
                </div>
            )}
        </>
    );
}
