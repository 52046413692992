import LoadingIcon from "components/loading-icon";
import useLanguage from "hooks/language";
import React, { CSSProperties, useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { CSSTransition } from "react-transition-group";
import Appointment from "../../models/appointment";
import Clinic, { ClinicLaboratoryModel } from "../../models/clinic";
import AppointmentDatetimePicker from "../appointment-datetime-picker";
import ClinicCard from "./clinic-card";
import PrivateLaboratoryBanner from "../../components/private-laboratory-banner";
import styles from "./clinic-list.module.css";

interface ClinicListProps {
    clinics: Clinic[];
    appointment?: Appointment;
    hasDateSelect?: boolean;
    isVisible?: boolean;
    onClinicSelect?: (clinicId: number | null) => void;
    onDateSelect?: (datetime: string | null) => void;
    style?: CSSProperties;
    startDate?: string;
    isDisplayedInModal?: boolean;
}

const ClinicList: React.FunctionComponent<ClinicListProps> = ({
    clinics,
    appointment,
    hasDateSelect = false,
    onClinicSelect,
    onDateSelect,
    style,
    startDate,
    isDisplayedInModal = false,
}: ClinicListProps) => {
    const { translations } = useLanguage();

    // State
    const [selectedClinicId, setSelectedClinicId] = useState<number | null>(
        null
    );

    // Effects

    useEffect(() => {
        if (onClinicSelect) onClinicSelect(selectedClinicId);
    }, [selectedClinicId]);

    //Handler
    const setIdx = (index: number) => {
        index === selectedClinicId
            ? setSelectedClinicId(null)
            : setSelectedClinicId(index);
    };

    const renderChevron = (index: number) => {
        return index === selectedClinicId ? <ChevronUp /> : <ChevronDown />;
    };

    const renderClinic = (clinic: Clinic, isLaboratoryPrivate: boolean) => {
        return (
            <div className="d-flex flex-column " key={clinic.id} style={style}>
                <div
                    className={styles.clinicCardContainer}
                    onClick={() => setIdx(clinic.id)}
                >
                    <ClinicCard clinic={clinic} />
                    {hasDateSelect && renderChevron(clinic.id)}
                </div>
                {hasDateSelect && (
                    <CSSTransition
                        in={selectedClinicId === clinic.id}
                        timeout={500}
                        classNames={{
                            enter: styles.enter,
                            enterActive: styles.enterActive,
                            enterDone: styles.enterDone,
                            exit: styles.exit,
                            exitActive: styles.exitActive,
                        }}
                        unmountOnExit
                        mountOnEnter
                    >
                        <div>
                            {isLaboratoryPrivate && <PrivateLaboratoryBanner />}

                            <AppointmentDatetimePicker
                                clinicId={clinic.id}
                                appointment={appointment}
                                onSelectDate={onDateSelect}
                                startDate={startDate ?? undefined}
                            />

                            {isLaboratoryPrivate && (
                                <p
                                    className={
                                        styles["feeDisclaimer__paragraph"]
                                    }
                                >
                                    {
                                        translations.clinicSelection
                                            .privateLaboratory.disclaimer
                                    }
                                </p>
                            )}
                        </div>
                    </CSSTransition>
                )}
            </div>
        );
    };

    return (
        <>
            {clinics.length === 0 || !clinics ? (
                <div className={styles.loadingIconContainer}>
                    <LoadingIcon />
                </div>
            ) : (
                <div
                    className={
                        isDisplayedInModal ? "" : styles.clinicListContainer
                    }
                >
                    {clinics
                        .sort(sortClinics)
                        .map((clinic) =>
                            renderClinic(
                                clinic,
                                clinic.laboratoryModel ===
                                    ClinicLaboratoryModel.Private
                            )
                        )}
                </div>
            )}
        </>
    );
};

function sortClinics(a: Clinic, b: Clinic) {
    // Francis asked to deploy a quick solution for the Clinic sorting
    // He wants a specific order for the clinics
    // I created a ticket to clean this up afterward and improve the way we handle this
    // GEN-427 - https://www.notion.so/prelib/Patient-Portal-Improve-Clinic-list-sorting-1c2186789a8f8070bf95e636f51b9195

    const clinicOrder = [
        { name: "Crescent", isPrivate: false },
        { name: "Berri", isPrivate: false },
        { name: "Saint Laurent", isPrivate: true },
        { name: "Crescent", isPrivate: true },
        { name: "Berri", isPrivate: true },
        { name: "Québec - Ste-Foy", isPrivate: false },
        { name: "Ville de Sherbrooke", isPrivate: false },
    ];

    const aIsPrivate = a.laboratoryModel === ClinicLaboratoryModel.Private;
    const bIsPrivate = b.laboratoryModel === ClinicLaboratoryModel.Private;

    let aIndex = -1;
    let bIndex = -1;

    for (let i = 0; i < clinicOrder.length; i++) {
        const orderItem = clinicOrder[i];

        if (
            a.name.includes(orderItem.name) &&
            aIsPrivate === orderItem.isPrivate &&
            aIndex === -1
        ) {
            aIndex = i;
        }

        if (
            b.name.includes(orderItem.name) &&
            bIsPrivate === orderItem.isPrivate &&
            bIndex === -1
        ) {
            bIndex = i;
        }
    }

    if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
    }

    if (aIndex !== -1) return -1;
    if (bIndex !== -1) return 1;

    return a.name.localeCompare(b.name);
}

export default ClinicList;
