import useLanguage from "../../../hooks/language";
import { kebabCase } from "lodash";
import { DateTime } from "luxon";
import { DEFAULT_TIMEZONE } from "../../../utils/constants";
import { createIcsFile } from "../../../utils/generateIcsFile";
import styles from "./appointment-confirmation.module.css";
import { ClinicLaboratoryModel } from "models/clinic";
import PrivateLaboratoryBanner from "components/private-laboratory-banner";
import checkIcon from "../../../assets/images/check-green.svg";
import MapPinIcon from "../../../assets/images/map-pin.svg";
import clockIcon from "../../../assets/images/clock-icon.svg";
import { useParams, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import useAuthUser from "hooks/auth-user";
import routes from "utils/routes";
import { isAppointment } from "utils/appointment-helpers";
import { useAsync } from "react-async-hook";
import { getLinkedAppointmentId } from "models/phone-appointment";

export default function AppointmentConfirmation(): JSX.Element | null {
    const { translations, language } = useLanguage();
    const appointmentConfirmationTranslation =
        translations.appointmentConfirmation;
    const reminderTranslations = appointmentConfirmationTranslation.reminders;
    const icsFileName =
        kebabCase(appointmentConfirmationTranslation.icsFileTitle) + ".ics";
    const { id, type } = useParams<{
        id: string;
        type: string;
    }>();
    const { push } = useHistory();
    const isPhoneAppointment = type === "phone";
    const [authUser] = useAuthUser();
    const confirmedAppointment = isPhoneAppointment
        ? authUser?.phoneAppointments.find(
              (phoneAppointment) => phoneAppointment.id.toString() === id
          )
        : authUser?.appointments.find(
              (appointment) => appointment.id.toString() === id
          );

    if (!confirmedAppointment) {
        NotificationManager.error(translations.notification.unexpectedError);
        push(routes.APPOINTMENTS);
        return null;
    }

    const getAppointmentDateTime = () =>
        isAppointment(confirmedAppointment)
            ? confirmedAppointment.datetime
            : confirmedAppointment.startAt;

    const getFollowUpType = () => {
        if (isAppointment(confirmedAppointment)) {
            return confirmedAppointment.followUpType;
        }

        const linkableID = getLinkedAppointmentId(confirmedAppointment);

        if (linkableID) {
            const linkedAppointment = authUser?.appointments.find(
                (appointment) => appointment.id === linkableID
            );
            return linkedAppointment?.followUpType;
        }

        return null;
    };

    const followUpType = getFollowUpType();

    const appointmentDateTime = DateTime.fromISO(getAppointmentDateTime(), {
        zone: DEFAULT_TIMEZONE,
    });

    const formattedAppointmentDate =
        appointmentDateTime.setLocale(language).toLocaleString({
            month: "long",
            day: "numeric",
            year: "numeric",
        }) + `, ${appointmentDateTime.toFormat("h:mm a")}`;
    const appointmentLocation = isAppointment(confirmedAppointment)
        ? confirmedAppointment.clinic.address
        : appointmentConfirmationTranslation.phoneAppointmentLocation;

    const { result: iCalendar } = useAsync(async () => {
        return await createIcsFile({
            eventLocation: appointmentLocation,
            eventDateTime: DateTime.fromISO(getAppointmentDateTime(), {
                zone: DEFAULT_TIMEZONE,
            }),
            fileName: icsFileName,
            title: appointmentConfirmationTranslation.title,
        });
    }, [language]);

    return (
        <div className={`container--narrow ${styles.confirmationContainer}`}>
            <div>
                <div className={styles.confirmationHeader__icon}>
                    <img src={checkIcon} />
                </div>
                <h1 className={styles.confirmationHeader__title}>
                    {appointmentConfirmationTranslation.title}
                </h1>

                <p className={styles.confirmationHeader__subtitle}>
                    {appointmentConfirmationTranslation.subtitle}{" "}
                    <a className={styles.link} href="mailto:info@prelib.com">
                        info@prelib.com
                    </a>
                    .
                </p>
            </div>

            <div>
                <div className={styles.appointmentDetails}>
                    <img
                        src={clockIcon}
                        className={styles.appointmentDetails__icon}
                    />
                    <div>
                        <span className={styles.appointmentDetails__title}>
                            {formattedAppointmentDate}
                        </span>
                        <span className={styles.appointmentDetails__linkList}>
                            {confirmedAppointment?.addToGoogleCalendarLink && (
                                <span
                                    className={
                                        styles.appointmentDetails__linkItem
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            confirmedAppointment?.addToGoogleCalendarLink
                                        }
                                        className={styles.link}
                                    >
                                        Google Calendar
                                    </a>
                                </span>
                            )}
                            {confirmedAppointment?.addToOutlookCalendarLink && (
                                <span
                                    className={
                                        styles.appointmentDetails__linkItem
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            confirmedAppointment?.addToOutlookCalendarLink
                                        }
                                        className={styles.link}
                                    >
                                        Outlook
                                    </a>
                                </span>
                            )}
                            {iCalendar && (
                                <span
                                    className={
                                        styles.appointmentDetails__linkItem
                                    }
                                >
                                    <a
                                        href={iCalendar.url}
                                        download={icsFileName}
                                        aria-label={`Download calendar file: ${icsFileName}`}
                                        className={styles.link}
                                    >
                                        ICalendar
                                    </a>
                                </span>
                            )}
                        </span>
                    </div>
                </div>

                {isAppointment(confirmedAppointment) && (
                    <div className={styles.appointmentDetails}>
                        <img
                            src={MapPinIcon}
                            className={styles.appointmentDetails__icon}
                        />

                        <div>
                            <span className={styles.appointmentDetails__title}>
                                {confirmedAppointment.clinic.name}
                            </span>
                            <span
                                className={
                                    styles.appointmentDetails__description
                                }
                            >
                                {confirmedAppointment.clinic.address}
                            </span>
                            <span
                                className={styles.appointmentDetails__linkList}
                            >
                                <span
                                    className={
                                        styles.appointmentDetails__linkItem
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.google.com/maps/search/${confirmedAppointment.clinic.address}`}
                                        className={styles.link}
                                    >
                                        Google Maps
                                    </a>
                                </span>
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {isAppointment(confirmedAppointment) &&
                confirmedAppointment.clinic.laboratoryModel ===
                    ClinicLaboratoryModel.Private && (
                    <PrivateLaboratoryBanner />
                )}

            <div>
                <p className={styles.confirmationFooter__title}>
                    {reminderTranslations.title}
                </p>
                <ul className={styles.confirmationFooter__contentList}>
                    {!isPhoneAppointment && (
                        <li className={styles.confirmationFooter__contentItem}>
                            <strong>
                                {reminderTranslations.bringRAMQ.main}
                            </strong>
                            {reminderTranslations.bringRAMQ.note}
                        </li>
                    )}
                    <li className={styles.confirmationFooter__contentItem}>
                        <strong>{reminderTranslations.beOnTime.main}</strong>
                    </li>
                    <li className={styles.confirmationFooter__contentItem}>
                        <strong>{reminderTranslations.cancelEarly.main}</strong>
                        {reminderTranslations.cancelEarly.note}
                    </li>
                </ul>
            </div>

            {followUpType && (
                <p
                    className={styles.confirmationFooter_footNote}
                    dangerouslySetInnerHTML={{
                        __html:
                            appointmentConfirmationTranslation
                                .serviceDescription[followUpType],
                    }}
                />
            )}
        </div>
    );
}
