import { createEvent } from "ics";
import { DateTime } from "luxon";

export async function createIcsFile({
    eventLocation,
    eventDateTime,
    fileName,
    title,
}: {
    eventLocation: string;
    eventDateTime: DateTime;
    fileName: string;
    title: string;
}): Promise<{ url: string; icsFile: File } | null> {
    try {
        const start: [number, number, number, number, number] = [
            eventDateTime.year,
            eventDateTime.month,
            eventDateTime.day,
            eventDateTime.hour,
            eventDateTime.minute,
        ];

        const icsFile: File = await new Promise((resolve, reject) => {
            createEvent(
                {
                    title: title,
                    start: start,
                    startInputType: "local",
                    startOutputType: "local",
                    duration: { minutes: 10 },
                    location: eventLocation,
                },
                (error, value) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(
                            new File([value], fileName, {
                                type: "text/calendar",
                            })
                        );
                    }
                }
            );
        });
        const url = URL.createObjectURL(icsFile as Blob);
        return {
            url,
            icsFile,
        };
    } catch (error) {
        console.error("Failed to create ICS file:", error);
        return null;
    }
}
