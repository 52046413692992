import { List, ListItem } from "components/list";
import useLanguage from "hooks/language";
import { StyledString } from "translations/types";
import { FollowUpType } from "models/appointment";
import styles from "./service-select.module.css";

type ServiceSelectProps = {
    expressServiceIsForced: boolean;
    selectedFollowUp: FollowUpType;
    onSelectFollowUp: (selectedFollowUp: FollowUpType) => void;
};

export default function ServiceSelect({
    expressServiceIsForced,
    selectedFollowUp,
    onSelectFollowUp,
}: ServiceSelectProps): JSX.Element {
    const { translations } = useLanguage();
    const t = translations.upfrontPaymentSection.followUpChoices;

    if (expressServiceIsForced) {
        return (
            <div className={styles.serviceSelect__cardGroup}>
                <span className={styles.serviceSelect__legend}>
                    {t.noRamqExplanation}
                </span>

                <List className={styles.serviceCard__content}>
                    {t.express.description.map((line, i) => (
                        <ListItem key={`description-item-${i}`}>
                            <span
                                dangerouslySetInnerHTML={{ __html: line }}
                            ></span>
                        </ListItem>
                    ))}
                    {t.express.footer && (
                        <ListItem noMarker>{t.express.footer}</ListItem>
                    )}
                </List>
            </div>
        );
    }

    return (
        <fieldset className={styles.serviceSelect__cardGroup}>
            <legend className={styles.serviceSelect__legend}>{t.legend}</legend>
            <SelectServiceRadioButton
                translations={t.express}
                value={FollowUpType.Express}
                selectedValue={selectedFollowUp}
                onSelect={onSelectFollowUp}
            />
            <SelectServiceRadioButton
                translations={t.regular}
                value={FollowUpType.Regular}
                selectedValue={selectedFollowUp}
                onSelect={onSelectFollowUp}
            />
        </fieldset>
    );
}

type SelectServiceRadioButtonProps = {
    translations: {
        title: string;
        description: StyledString[];
        footer?: string;
    };
    value: FollowUpType;
    selectedValue: FollowUpType;
    onSelect: (selectedValue: FollowUpType) => void;
};
function SelectServiceRadioButton({
    translations,
    value,
    selectedValue,
    onSelect,
}: SelectServiceRadioButtonProps) {
    return (
        <label className={styles.serviceCard}>
            <span className={styles.serviceCard__header}>
                <input
                    type="radio"
                    name="service"
                    value={value}
                    checked={value === selectedValue}
                    onChange={(event) =>
                        onSelect(event.target.value as FollowUpType)
                    }
                    className={styles.serviceCard__radioButton}
                />
                <strong className={styles.serviceCard__title}>
                    {translations.title}
                </strong>
            </span>
            <List
                className={`${styles.serviceCard__content} ${styles.serviceCard__accordion}`}
            >
                {translations.description.map((line, i) => (
                    <ListItem key={`description-item-${i}`}>
                        <span dangerouslySetInnerHTML={{ __html: line }}></span>
                    </ListItem>
                ))}
                {translations.footer && (
                    <ListItem noMarker>{translations.footer}</ListItem>
                )}
            </List>
        </label>
    );
}
