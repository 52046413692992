import React from "react";
import useLanguage from "../../../hooks/language";
import Clinic, { ClinicLaboratoryModel } from "../../../models/clinic";
import styles from "./clinic-card.module.css";

export default function ClinicCard({
    clinic,
}: {
    clinic: Clinic;
}): React.ReactElement {
    const { translations } = useLanguage();
    const isPrivateLaboratory =
        clinic.laboratoryModel === ClinicLaboratoryModel.Private;

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <div className={styles.clinicCardHeader}>
                    <h3 className={styles.cardContainerTitle}>{clinic.name}</h3>
                    {isPrivateLaboratory && (
                        <span className={styles["laboratoryNote__span"]}>
                            {
                                translations.clinicSelection.privateLaboratory
                                    .cardTitle
                            }
                        </span>
                    )}
                </div>
                <a
                    href={`https://maps.google.com/?q=${clinic.address}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {clinic.address}
                </a>
            </div>
        </div>
    );
}
