import useFeatureManager from "hooks/featureManager";
import { createContext, PropsWithChildren, useContext } from "react";
import { IFeatureFlagValue } from "utils/feature-management";

type FeatureFlagContextValue = Map<string, IFeatureFlagValue | null>;
const FeatureFlagContext = createContext<FeatureFlagContextValue>(new Map());

/**
 * Provider to register the status of a given feature flag
 * This provider will not render unnecessarily if the feature flag has already been verified further up.
 * This provider will allow access to other feature flags that have been verified above in the component tree.
 */
export function FeatureFlagContextProvider({
    flags,
    children,
}: PropsWithChildren<{ flags: string[] }>): JSX.Element {
    const context = useContext(FeatureFlagContext);
    const [featureManager] = useFeatureManager();

    for (const flagName of flags) {
        if (context.has(flagName)) {
            console.warn(
                `Duplicate FeatureFlagContextProvider detected for "${flagName}"`
            );
            continue;
        }

        const currentFlag = featureManager?.getFeatureFlag(flagName) ?? null;
        context.set(flagName, currentFlag);
    }

    return (
        <FeatureFlagContext.Provider value={context}>
            {children}
        </FeatureFlagContext.Provider>
    );
}

/**
 * Custom hook to determine if a feature flag is enabled.
 *
 * @throws when the flag has never been fetched and saved in the FeatureFlagContext before
 */
export function useIsFeatureFlagEnabled(key: string, value: string): boolean {
    const context = useContext(FeatureFlagContext);

    if (!context.has(key)) {
        throw new Error(
            `useIsFeatureFlagEnabled must be used within a FeatureFlagContextProvider : ${key} - ${value}`
        );
    }

    return context.get(key)?.value === value;
}

/**
 * Custom hook to determine if a flag is served to the user and access its possible payload.
 *
 * It is possible to exclude certain variants from being considered enabled, while still accessing their payload if it exists.
 *
 * @throws when the flag has never been fetched and saved in the FeatureFlagContext before
 */
export function useFeatureFlag(
    key: string,
    options: { ignore: string }
): { isEnabled: boolean; payload: any } {
    const context = useContext(FeatureFlagContext);

    if (!context.has(key)) {
        throw new Error(
            `useFeatureFlag must be used within a FeatureFlagContextProvider : ${key} `
        );
    }

    const currentFlag = context.get(key);
    return {
        isEnabled:
            !!currentFlag?.value && currentFlag?.value !== options.ignore,
        payload: currentFlag?.payload ?? undefined,
    };
}
