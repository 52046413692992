import React from "react";
import { Calendar, Phone } from "react-feather";
import { DateTime } from "luxon";
import { Card } from "components/card";
import { Button } from "..";
import PhoneAppointment, {
    isCanceled,
    isMissed,
    isUpcoming,
    getLinkedAppointmentId,
    isRejected,
} from "../../models/phone-appointment";
import useAuthUser from "../../hooks/auth-user";
import useLanguage from "../../hooks/language";

import styles from "./appointment-card.module.css";
import { DEFAULT_TIMEZONE } from "../../utils/constants";

interface PhoneAppointmentCardProps {
    phoneAppointment: PhoneAppointment;
    onChooseDateClick?: () => void;
    onRejectClick?: () => void;
}

const PhoneAppointmentCard: React.FunctionComponent<PhoneAppointmentCardProps> = ({
    phoneAppointment,
    onChooseDateClick,
    onRejectClick,
}: PhoneAppointmentCardProps) => {
    const [authUser] = useAuthUser();
    const { translations, language } = useLanguage();
    const t = translations.appointmentsSection.phoneAppointmentCard;

    // Rendering

    const { startAt } = phoneAppointment;

    // Local date time string
    const date = startAt
        ? DateTime.fromISO(startAt).setZone(DEFAULT_TIMEZONE)
        : null;
    const day = date?.day;
    const shortMonth = date?.setLocale(language).toFormat("MMM");

    // Scheduled
    const scheduled = date !== null;

    // Upcoming
    const upcoming = isUpcoming(phoneAppointment);

    // Canceled
    const canceled = isCanceled(phoneAppointment);

    // Missed
    const missed = isMissed(phoneAppointment);

    // rejected
    const rejected = isRejected(phoneAppointment);

    // Can choose date time
    const canChooseDateTime = upcoming || missed;

    // Associated appointment
    const linkedAppointmentId = getLinkedAppointmentId(phoneAppointment);
    const linkedAppointment = authUser?.appointments.find(
        ({ id }) => id === linkedAppointmentId
    );
    const linkedAppointmentDate = linkedAppointment
        ? DateTime.fromISO(linkedAppointment.datetime).setZone(DEFAULT_TIMEZONE)
        : null;

    // Can reject
    const canReject =
        !rejected &&
        (!linkedAppointment || phoneAppointment.isAboutPrep) &&
        !canceled &&
        isUpcoming;

    // Titles
    const title = date
        ? phoneAppointment.isAboutPrep
            ? t.prepDateIndicator(
                  date.setLocale(language).toLocaleString(DateTime.DATETIME_MED)
              )
            : t.dateIndicator(
                  date.setLocale(language).toLocaleString(DateTime.DATETIME_MED)
              )
        : phoneAppointment.isAboutPrep
        ? t.prepTitle
        : t.title;

    const isDisabled = canceled || rejected;
    const isUrgent = missed || (!scheduled && !rejected);

    return (
        <Card>
            <Card.Header>
                <Phone
                    className={`${isUrgent ? "urgent" : ""} ${
                        isDisabled ? styles.canceled : ""
                    }`}
                />
                <h2>{title}</h2>
            </Card.Header>

            <Card.Body>
                <div className={styles.left}>
                    {/* Date indicator */}
                    <div
                        className={`${styles.dateHolder} ${
                            isDisabled ? styles.canceled : ""
                        }`}
                    >
                        <Calendar />
                        {day !== null && (
                            <span className={styles.day}>{day}</span>
                        )}
                        {shortMonth !== null && (
                            <span className={styles.month}>{shortMonth}</span>
                        )}
                        {!date && <span className={styles.what}>?</span>}
                    </div>

                    {/* Buttons */}
                    {canChooseDateTime && (
                        <Button type="secondary" onClick={onChooseDateClick}>
                            {scheduled && !missed
                                ? t.editButtonText
                                : t.chooseButtonText}
                        </Button>
                    )}
                    {canReject && (
                        <Button type="secondary" onClick={onRejectClick}>
                            {t.rejectButtonText}
                        </Button>
                    )}
                </div>

                <div className={styles.right}>
                    {rejected && <b>{t.rejectedIndicator}</b>}
                    {missed && <p className="urgent">{t.missedMessage}</p>}
                    {!scheduled && !rejected && !canceled && (
                        <p className="urgent">{t.chooseDate}</p>
                    )}
                    {phoneAppointment.type === "PREP_ELIGIBILITY" ? (
                        <>
                            <p>{t.prepDescription}</p>
                            <p>{t.prepDescription2}</p>
                        </>
                    ) : (
                        linkedAppointmentDate !== null && (
                            <p>
                                {t.descriptionLine1(
                                    linkedAppointmentDate
                                        .setLocale(language)
                                        .toLocaleString(DateTime.DATE_MED)
                                )}
                            </p>
                        )
                    )}
                    {canceled && <b>{t.canceledIndicator}</b>}
                    {upcoming && !phoneAppointment.isAboutPrep && (
                        <p>{t.descriptionLine2}</p>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export default PhoneAppointmentCard;
