import {
    AnalyticsBrowser,
    AnalyticsBrowserSettings,
    InitOptions,
    Traits,
} from "@customerio/cdp-analytics-browser";
import { toUnixTime } from "utils/date";
import { ICDPAnalyticsService } from "..";
import { UserActionEvent } from "../events";
import { generateAnalyticsUserID } from "../user-id";

export interface ICustomerIOConfig {
    writeKey: string;
    cdnURL: string;
    isProxyCdnURL: boolean;
}

export class CustomerIOAnalytics implements ICDPAnalyticsService {
    private readonly _analyticsBrowser: AnalyticsBrowser;

    public constructor(
        private readonly writeKey: string,
        private readonly cdnURL: string,
        private readonly isProxyCdnURL: boolean
    ) {
        if (!writeKey) {
            throw new Error("writeKey");
        }

        if (!cdnURL || (!isProxyCdnURL && !cdnURL.endsWith(".customer.io"))) {
            throw new Error("cdnURL");
        }

        if (isProxyCdnURL && !cdnURL.endsWith(".prelib.ca")) {
            throw new Error("proxy cdnURL");
        }

        const customerIOSettings: AnalyticsBrowserSettings = {
            writeKey: this.writeKey,
        };
        const initOptions: InitOptions = {};

        if (!this.isProxyCdnURL) {
            customerIOSettings.cdnURL = this.cdnURL;
        } else {
            // As defined in: https://customer.io/docs/cdp/sources/connections/javascript/js-source-proxy/#client-side-javascript-snippet
            customerIOSettings.cdnURL = `https://${this.cdnURL}`;
            initOptions.integrations = {
                "Customer.io Data Pipelines": {
                    apiHost: `${this.cdnURL}/v1`,
                },
            };
        }

        this._analyticsBrowser = AnalyticsBrowser.load(
            customerIOSettings,
            initOptions
        );
    }

    public identifyAnonymousUser<T>(properties?: T): void {
        if (!properties || Object.keys(properties).length === 0) {
            this._analyticsBrowser.identify();

            return;
        }

        this._analyticsBrowser.identify(properties as Traits);
    }

    public identifyUser(
        firstName: string,
        email: string,
        phoneNumber: string,
        language: string,
        nextAppointmentAt: string,
        lastAppointmentAt: string,
        accountCreatedAt: string
    ): void {
        const userID = generateAnalyticsUserID(email);
        const traits: any = {
            firstName: firstName,
            email: email,
            phoneNumber: phoneNumber,
        };

        if (nextAppointmentAt) {
            traits.nextAppointmentAt = toUnixTime(nextAppointmentAt);
        } else {
            traits.nextAppointmentAt = "";
        }

        if (lastAppointmentAt) {
            traits.lastAppointmentAt = toUnixTime(lastAppointmentAt);
        } else {
            traits.lastAppointmentAt = "";
        }

        if (accountCreatedAt) {
            traits.created_at = toUnixTime(accountCreatedAt);
        }

        if (language) {
            traits.language = language;
        }

        this._analyticsBrowser.identify(userID.toString(), { ...traits });
    }

    public trackEvent<T>(
        eventName: UserActionEvent,
        eventProperties?: T
    ): void {
        if (!eventProperties || Object.keys(eventProperties).length === 0) {
            this._analyticsBrowser.track(eventName);

            return;
        }

        this._analyticsBrowser.track(eventName, eventProperties);
    }

    public disconnectUser(): void {
        this._analyticsBrowser.reset();
    }
}
