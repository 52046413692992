export enum ClinicLaboratoryModel {
    All = "all",
    Public = "public",
    Private = "private",
}

export default interface Clinic {
    id: number;
    code: number;
    timezone: string;
    name: string;
    city: string;
    address: string;
    nextAvailableTime: string;
    googleLocationId?: string | null;
    laboratoryModel: ClinicLaboratoryModel;
}
