import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentForm from "./payment-form";
import useLanguage from "../../../../hooks/language";
import useAuthUser from "hooks/auth-user";
import ServiceSelect from "./service-select";
import { FollowUpType } from "models/appointment";
import styles from "./upfront-payments.module.css";
import { useFeatureFlag } from "pages/home/feature-flag-context/featureFlagContext";

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

interface PaymentProps {
    selectedFollowUp: FollowUpType;
    onSelectFollowUp: (selectedFollowUp: FollowUpType) => void;
    onCompletePayment: (canMakePayment: boolean) => void;
    onSkipPaymentClicked: (willMakeCashPayment: boolean) => void;
}

const Payment: React.FunctionComponent<PaymentProps> = ({
    selectedFollowUp,
    onSelectFollowUp,
    onCompletePayment,
    onSkipPaymentClicked,
}: PaymentProps) => {
    const [authUser] = useAuthUser();
    const { translations, language } = useLanguage();
    const tUpfrontPayments = translations.upfrontPaymentSection;
    const tFollowUpChoices = translations.upfrontPaymentSection.followUpChoices;
    const tLegacyText = translations.upfrontPaymentSection.legacyText;

    const prelibExpressFlag = useFeatureFlag("results-by-nurses", {
        ignore: "control",
    });

    const isUserRamqAvailable = !!authUser?.ramqNumber;

    const getTotalPrice = (): [string, boolean] => {
        switch (selectedFollowUp) {
            case FollowUpType.Express:
                return [
                    prelibExpressFlag?.payload.displayAmount ??
                        tFollowUpChoices.express.totalAmount,
                    true,
                ];
            case FollowUpType.Regular:
                return isUserRamqAvailable
                    ? [tFollowUpChoices.regular.totalNoCharge, false]
                    : [tFollowUpChoices.regular.totalAmount, true];
        }
    };
    const [priceAmount, isUserCharged] = getTotalPrice();

    // Rendering

    return (
        <div className={`container--narrow ${styles.upfrontPaymentsSection}`}>
            {prelibExpressFlag.isEnabled ? (
                <>
                    <h2 className={styles.upfrontPaymentsSection__title}>
                        {tUpfrontPayments.sectionTitle}
                    </h2>
                    <ServiceSelect
                        expressServiceIsForced={!isUserRamqAvailable}
                        selectedFollowUp={selectedFollowUp}
                        onSelectFollowUp={onSelectFollowUp}
                    />
                    <strong className={styles.upfrontPaymentsSection__total}>
                        {tUpfrontPayments.totalLabel} {priceAmount}
                    </strong>
                    <p className={styles.upfrontPaymentsSection__chargeWarning}>
                        {tUpfrontPayments.chargeWarning}
                    </p>
                </>
            ) : isUserRamqAvailable ? (
                <div>
                    <h2>{tLegacyText.ramqHeader}</h2>
                    <p>{tLegacyText.ramqChargeExplanation}</p>
                </div>
            ) : (
                <div>
                    <h2>{tLegacyText.noRamqHeader}</h2>
                    <p>{tLegacyText.noRamqChargeExplanation}</p>
                    <p>{tLegacyText.noRamqChargeExplanationNote}</p>
                </div>
            )}

            <Elements stripe={stripePromise} options={{ locale: language }}>
                <PaymentForm
                    onCompletePayment={onCompletePayment}
                    onSkipPaymentClicked={onSkipPaymentClicked}
                    appointmentFollowUpType={selectedFollowUp}
                    submitButtonText={
                        isUserCharged
                            ? `${tUpfrontPayments.paymentForm.payButtonPrefix} ${priceAmount}`
                            : tUpfrontPayments.paymentForm.noChargeButtonText
                    }
                />
            </Elements>
            {prelibExpressFlag.isEnabled && (
                <p
                    className={
                        styles.upfrontPaymentsSection__timeEstimateWarning
                    }
                >
                    {tUpfrontPayments.timeEstimateWarning}
                </p>
            )}
        </div>
    );
};

export default Payment;
